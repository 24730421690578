import React from 'react';
import logo from '../icons/casacloud_watermark.png'; // Ensure this path is correct
import blogIcon from '../icons/blog.png';
import youtubeIcon from '../icons/YouTube-Icon-PNG.png';
import linkedinIcon from '../icons/Linked-In-Icon-PNG.png';
import githubIcon from '../icons/github-mark-white.png';
import stackoverflowIcon from '../icons/stackoverflow.png';
import patreonIcon from '../icons/PATREON_SYMBOL_1_WHITE_RGB.png';
import arrow from '../icons/Yellow-Stripe-Arrow-PNG.png'; // Path to the arrow image

const tabs = [
  { label: 'Patreon', angle: 150, icon: patreonIcon, transitionpx: '135px', left: '-50px', top: '-50px', transformOrigin: '52%' },
  { label: 'Blog', angle: 90, icon: blogIcon, transitionpx: '119px', left: '-50px', top: '-50px', transformOrigin: '-71%' },
  { label: 'Stack Overflow', angle: 30, icon: stackoverflowIcon, transitionpx: '46px', left: '-150px', top: '150px', transformOrigin: '-559%' },
  { label: 'GitHub', angle: 210, icon: githubIcon, transitionpx: '154px', left: '50', top: '0px', transformOrigin: '158%' },
  { label: 'LinkedIn', angle: 330, icon: linkedinIcon, transitionpx: '277px', left: '20px', top: '-50px', transformOrigin: '766%' },
  { label: 'YouTube', angle: 270, icon: youtubeIcon, transitionpx: '193px', left: '-50px', top: '-50px', transformOrigin: '302%' },
];

const handleClick = (label) => {
  switch (label) {
    case 'GitHub':
      window.open('https://github.com/Casa-Cloud', '_blank');
      break;
    case 'LinkedIn':
      window.open('https://www.linkedin.com/in/alok-adhao-8a571857/?originalSubdomain=sg', '_blank');
      break;
    case 'YouTube':
      window.open('https://www.youtube.com/@casa-cloud', '_blank');
      break;
    case 'Stack Overflow':
      window.open('https://stackoverflow.com/users/4373015/alok-adhao', '_blank');
      break;
    case 'Blog':
      window.alert('Coming Soon!');
      break;
    case 'Patreon':
      window.open('https://www.patreon.com/casacloud', '_blank');
      break;
    default:
      fetch(`http://localhost:5000/api/${label.toLowerCase()}`, { method: 'GET' })
        .then(response => response.json())
        .then(data => {
          console.log(data);
          window.open(`/${label.toLowerCase()}`, '_blank');
        })
        .catch(error => console.error('Error:', error));
  }
};

const getTabStyles = (label) => {
  switch (label) {
    case 'GitHub':
      return '';
    case 'LinkedIn':
      return '';
    case 'YouTube':
      return '';
    case 'Stack Overflow':
      return '';
    case 'Blog':
      return 'pb-2 relative';
    case 'Patreon':
      return '';
    default:
      return '';
  }
};

const CasacloudTabs = () => {
  const circleRadius = 100;
  const tabRadius = 360;

  return (
    <div className="flex bg-gradient-to-r from-blue-500 to-purple-500 justify-center items-center h-screen relative overflow-hidden">
      <div className="relative w-60 h-80">
        <div className="absolute bottom-30 left-16 flex justify-center items-center">
          <div className="rounded-full w-50 h-50 flex justify-center items-center text-center">
            <img src={logo} alt="Logo" className="w-full h-full object-cover rounded-full" />
          </div>
        </div>
        {tabs.map((tab, index) => {
          const angle = tab.angle;
          const x = tabRadius * Math.cos((angle * Math.PI) / 180);
          const y = tabRadius * Math.sin((angle * Math.PI) / 180);

          return (
            <div
              key={index}
              className={`absolute flex flex-col items-center cursor-pointer`}
              style={{
                transform: `translate(${x}px, ${y}px)`,
                left: '50%',
                top: '0%',
                transformOrigin: 'center center',
              }}
              onClick={() => handleClick(tab.label)}
            >
              <div className="absolute">
                <img
                  src={arrow}
                  alt="Arrow"
                  className=""
                  style={{
                    transform: `rotate(${angle}deg)`,
                    transformOrigin: `${tab.transitionpx} ${tab.transformOrigin}`,
                    left: tab.left,
                    top: tab.top,
                  }}
                />
              </div>
              <div className="rounded w-27 h-27 flex justify-center items-center mt-12">
                <img src={tab.icon} alt={tab.label} className={`w-full h-full object-cover  ${getTabStyles(tab.label)}"`} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CasacloudTabs;
// import React from 'react';
// import logo from '../icons/casacloud_watermark.png'; // Ensure this path is correct
// import blogIcon from '../icons/blog.png';
// import youtubeIcon from '../icons/YouTube-Icon-PNG.png';
// import linkedinIcon from '../icons/Linked-In-Icon-PNG.png';
// import githubIcon from '../icons/github-mark-white.png';
// import stackoverflowIcon from '../icons/stackoverflow.png';
// import patreonIcon from '../icons/PATREON_SYMBOL_1_WHITE_RGB.png';
// import arrow from '../icons/Yellow-Stripe-Arrow-PNG.png'; // Path to the arrow image

// const tabs = [
//   { label: 'Patreon', angle: 150, icon: patreonIcon, transitionpx: '135px', left: '-50px', top: '-50px', transformOrigin: '52%' },
//   { label: 'Blog', angle: 90, icon: blogIcon, transitionpx: '119px', left: '-50px', top: '-50px', transformOrigin: '-71%' },
//   { label: 'Stack Overflow', angle: 30, icon: stackoverflowIcon, transitionpx: '46px', left: '-150px', top: '150px', transformOrigin: '-559%' },
//   { label: 'GitHub', angle: 210, icon: githubIcon, transitionpx: '154px', left: '50', top: '0px', transformOrigin: '158%' },
//   { label: 'LinkedIn', angle: 330, icon: linkedinIcon, transitionpx: '277px', left: '20px', top: '-50px', transformOrigin: '766%' },
//   { label: 'YouTube', angle: 270, icon: youtubeIcon, transitionpx: '193px', left: '-50px', top: '-50px', transformOrigin: '302%' },
// ];

// const handleClick = (label) => {
//   switch (label) {
//     case 'GitHub':
//       window.open('https://github.com/Casa-Cloud', '_blank');
//       break;
//     case 'LinkedIn':
//       window.open('https://www.linkedin.com/in/alok-adhao-8a571857/?originalSubdomain=sg', '_blank');
//       break;
//     case 'YouTube':
//       window.open('https://www.youtube.com/@casa-cloud', '_blank');
//       break;
//     case 'Stack Overflow':
//       window.open('https://stackoverflow.com/users/4373015/alok-adhao', '_blank');
//       break;
//     case 'Blog':
//       window.alert('Coming Soon!');
//       break;
//     case 'Patreon':
//       window.open('https://www.patreon.com/casacloud', '_blank');
//       break;
//     default:
//       fetch(`http://localhost:5000/api/${label.toLowerCase()}`, { method: 'GET' })
//         .then(response => response.json())
//         .then(data => {
//           console.log(data);
//           window.open(`/${label.toLowerCase()}`, '_blank');
//         })
//         .catch(error => console.error('Error:', error));
//   }
// };

// const CasacloudTabs = () => {
//   const circleRadius = 100;
//   const tabRadius = 360;

//   return (
//     <div className="flex bg-gradient-to-r from-blue-500 to-purple-500 justify-center items-center h-screen relative overflow-hidden">
//       <div className="relative w-60 h-80">
//         <div className="absolute bottom-30 left-14 flex justify-center items-center">
//           <div className="rounded-full w-50 h-50 flex justify-center items-center text-center">
//             <img src={logo} alt="Logo" className="w-full h-full object-cover rounded-full" />
//           </div>
//         </div>
//         {tabs.map((tab, index) => {
//           const angle = tab.angle;
//           const x = tabRadius * Math.cos((angle * Math.PI) / 180);
//           const y = tabRadius * Math.sin((angle * Math.PI) / 180);

//           return (
//             <div
//               key={index}
//               className="absolute flex flex-col items-center cursor-pointer"
//               style={{
//                 transform: `translate(${x}px, ${y}px)`,
//                 left: '40%',
//                 top: '0%',
//                 transformOrigin: 'center center',
//               }}
//               onClick={() => handleClick(tab.label)}
//             >
//               <div className="absolute">
//                 <img
//                   src={arrow}
//                   alt="Arrow"
//                   className=""
//                   style={{
//                     transform: `rotate(${angle}deg)`,
//                     transformOrigin: `${tab.transitionpx} ${tab.transformOrigin}`,
//                     left: tab.left,
//                     top: tab.top,
//                   }}
//                 />
//               </div>
//               <div className="bg-transparent rounded w-28 h-28 flex justify-center items-center mt-12">
//                 <img src={tab.icon} alt={tab.label} className="w-full h-full object-cover" />
//               </div>
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// };

// export default CasacloudTabs;



